@import './variables-icons';

.icon-fw {
  text-align: center;
  width: 1.2857em;
}

@font-face {
  font-display: swap;
  font-family: iichi-icon;
  font-style: normal;
  font-weight: normal;
  src: url("@/fonts/iichi-icon.woff?6505efecbbe290624646ec3b1865f66b") format("woff"),
url("@/fonts/iichi-icon.ttf?6505efecbbe290624646ec3b1865f66b") format("truetype"),
url("@/fonts/iichi-icon.eot?6505efecbbe290624646ec3b1865f66b#iefix") format("embedded-opentype");
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: iichi-icon, sans-serif !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-vintage::before {
  content: $icon-vintage;
}

.icon-twitter::before {
  content: $icon-twitter;
}

.icon-truck::before {
  content: $icon-truck;
}

.icon-trash::before {
  content: $icon-trash;
}

.icon-times-circle::before {
  content: $icon-times-circle;
}

.icon-sort::before {
  content: $icon-sort;
}

.icon-shopping-cart::before {
  content: $icon-shopping-cart;
}

.icon-share::before {
  content: $icon-share;
}

.icon-search::before {
  content: $icon-search;
}

.icon-question-circle::before {
  content: $icon-question-circle;
}

.icon-plus-square-o::before {
  content: $icon-plus-square-o;
}

.icon-pinterest-p::before {
  content: $icon-pinterest-p;
}

.icon-person::before {
  content: $icon-person;
}

.icon-pencil::before {
  content: $icon-pencil;
}

.icon-ordermade::before {
  content: $icon-ordermade;
}

.icon-newspaper-o::before {
  content: $icon-newspaper-o;
}

.icon-multiple-img::before {
  content: $icon-multiple-img;
}

.icon-minus-square-o::before {
  content: $icon-minus-square-o;
}

.icon-minus-circle-outline::before {
  content: $icon-minus-circle-outline;
}

.icon-message::before {
  content: $icon-message;
}

.icon-list::before {
  content: $icon-list;
}

.icon-link::before {
  content: $icon-link;
}

.icon-line::before {
  content: $icon-line;
}

.icon-line-chart::before {
  content: $icon-line-chart;
}

.icon-jpy::before {
  content: $icon-jpy;
}

.icon-instagram::before {
  content: $icon-instagram;
}

.icon-image::before {
  content: $icon-image;
}

.icon-iichi-logo::before {
  content: $icon-iichi-logo;
}

.icon-home::before {
  content: $icon-home;
}

.icon-header-search::before {
  content: $icon-header-search;
}

.icon-header-search-active::before {
  content: $icon-header-search-active;
}

.icon-header-heart::before {
  content: $icon-header-heart;
}

.icon-header-heart-fill::before {
  content: $icon-header-heart-fill;
}

.icon-header-heart-active::before {
  content: $icon-header-heart-active;
}

.icon-header-clock::before {
  content: $icon-header-clock;
}

.icon-header-clock-active::before {
  content: $icon-header-clock-active;
}

.icon-header-cart::before {
  content: $icon-header-cart;
}

.icon-header-cart-active::before {
  content: $icon-header-cart-active;
}

.icon-gift::before {
  content: $icon-gift;
}

.icon-folder-open::before {
  content: $icon-folder-open;
}

.icon-flag::before {
  content: $icon-flag;
}

.icon-filter::before {
  content: $icon-filter;
}

.icon-file::before {
  content: $icon-file;
}

.icon-facebook::before {
  content: $icon-facebook;
}

.icon-fa-check::before {
  content: $icon-fa-check;
}

.icon-fa-bell::before {
  content: $icon-fa-bell;
}

.icon-eye::before {
  content: $icon-eye;
}

.icon-external-link::before {
  content: $icon-external-link;
}

.icon-exclamation-circle::before {
  content: $icon-exclamation-circle;
}

.icon-exchange::before {
  content: $icon-exchange;
}

.icon-envelope-square::before {
  content: $icon-envelope-square;
}

.icon-envelope-o::before {
  content: $icon-envelope-o;
}

.icon-drag-vertical::before {
  content: $icon-drag-vertical;
}

.icon-dots-vertical::before {
  content: $icon-dots-vertical;
}

.icon-dots-horizontal::before {
  content: $icon-dots-horizontal;
}

.icon-display::before {
  content: $icon-display;
}

.icon-cross1::before {
  content: $icon-cross1;
}

.icon-coupon::before {
  content: $icon-coupon;
}

.icon-cog::before {
  content: $icon-cog;
}

.icon-chevron-right::before {
  content: $icon-chevron-right;
}

.icon-checkbox-marked-circle::before {
  content: $icon-checkbox-marked-circle;
}

.icon-check1::before {
  content: $icon-check1;
}

.icon-check::before {
  content: $icon-check;
}

.icon-calendar::before {
  content: $icon-calendar;
}

.icon-arrow-right::before {
  content: $icon-arrow-right;
}

.icon-arrow-left::before {
  content: $icon-arrow-left;
}

.icon-arrow-bottom::before {
  content: $icon-arrow-bottom;
}
