#footer {
  background-color: #f7f7f7;
  margin-top: 30px;
  min-width: 980px;
  padding: 24px 20px 0;
}

#footer-inner {
  margin: 0 auto;
  position: relative;
  text-align: left;
  width: 980px;

  @media only screen and (max-width: $breakpoint-s) {
    width: auto;
  }
}

#footer-title {
  margin-bottom: 24px;

  > {
    .footer-logo {
      display: inline-block;
      margin-right: 24px;
      vertical-align: middle;
    }

    .tagline {
      font-size: 11px;
    }
  }
}

#footer-social {
  position: absolute;
  right: 0;
  top: 0;
}

.social-buttons {
  display: flex;
  height: 30px;

  > li {
    padding: 0 0 0 12px;

    a {
      background-color: $gray900;
      border-radius: 50%;
      box-sizing: border-box;
      color: #fff;
      display: block;
      font-size: 18px;
      height: 30px;
      line-height: 30px;
      width: 30px;

      &:hover {
        text-decoration: none;
      }

      &.tw {
        font-size: 14px;
      }
    }

    i {
      display: block;
      line-height: 30px;
      padding-left: 0.5px;
      text-align: center;
    }
  }
}

#footer-navigation {
  display: flex;

  .footer-heading {
    color: $gray900;
    font-weight: bold;
    white-space: nowrap;
  }

  a {
    color: $gray900;
  }
}

.footer-links {
  padding-right: 38px;

  &:nth-child(2) {
    padding-right: 31px;
  }

  &:last-child {
    padding-right: 0;
  }

  > {
    .footer-heading {
      font-size: 14px;
      height: 20px;
      margin-bottom: -22px;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    ul {
      margin-top: 32px;
    }
  }

  li {
    font-size: 12px;
    margin-bottom: 6px;
  }
}

#footer-meta {
  border-top: 1px solid $border-color;
  color: $gray900;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-top: 32px;
  padding: 12px 0;
}

#footer-meta-links {
  li {
    display: inline-block;
    margin-right: 12px;
  }

  a {
    color: $gray900;
  }
}

#footer-copyright {
  color: $gray900;
}

@media only screen and (max-width: $breakpoint-s) {
  #footer {
    margin-top: 0;
    min-width: inherit;
    padding: 8px 10px;

    #footer-title {
      margin-bottom: 15px;
      text-align: center;

      .footer-logo {
        display: block;
        margin: 18px 0;

        img {
          height: 22px;
          width: 51px;
        }
      }

      .tagline {
        font-size: 9px;
      }
    }

    #footer-social {
      position: static;
    }

    .social-buttons {
      justify-content: center;

      > li {
        padding: 0 8px;

        a {
          font-size: 20px;
          height: 32px;
          line-height: 32px;
          width: 32px;

          &.tw {
            font-size: 16px;
          }
        }

        i {
          line-height: 32px;
        }
      }
    }

    #footer-navigation {
      flex-wrap: wrap;
      margin: 25px 0 26px;
      padding: 12px 5px 0;
      white-space: inherit;
      width: auto;
    }

    .footer-links {
      padding-right: 0;
      width: 50%;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 16px;
      }

      &:nth-child(5),
      &:nth-child(6) {
        margin-top: -10px;
        padding-left: 50%;
        width: 100%;
      }

      .footer-heading {
        margin-bottom: 0;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      ul {
        margin-top: 0;
        padding: 5px 0;
        text-align: left;
      }

      li {
        color: $gray900;
        font-size: 12px;
        height: auto;
        margin-bottom: 0;
        width: 100%;

        a {
          display: block;
          padding: 5px 0;
        }
      }

      a {
        color: $gray900;
      }
    }

    #footer-meta {
      flex-direction: column;
      padding: 12px 0 4px;
    }

    #footer-meta-links {
      line-height: 1.8;
      margin-bottom: 10px;

      li {
        margin-right: 8px;
      }
    }

    #footer-copyright {
      font-size: 9px;
      text-align: center;
    }
  }
}
