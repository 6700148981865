@import './variables';

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
p,
blockquote,
table,
tr,
th,
td,
form,
fieldset,
legend,
input,
select,
textarea,
address {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

th,
td,
input,
textarea,
select,
label {
  font-family: inherit;
  font-size: 100%;
}

ul,
ol {
  list-style-type: none;
}

b,
strong {
  font-weight: bold;
}

svg {
  // unplugin-iconsのsvgのheight="auto"の指定が100%として扱われるのでCSSで上書きする
  height: auto;
}

button {
  background-color: transparent;
  border-style: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
}

fieldset {
  border: none;
}

input,
textarea,
select {
  outline-color: #007aff;
}

input[type='search'] {
  background-color: $white;

  &::-webkit-search-cancel-button {
    appearance: none;
  }
}

summary {
  display: block;

  &::-webkit-details-marker {
    display: none;
  }
}
