body.header-menu-open {
  @media only screen and (max-width: $breakpoint-s) {
    overflow: hidden;
  }
}

#container {
  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    position: relative;

    &::after {
      background-color: rgba(0, 0, 0, 40%);
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: opacity 0.15s;
      width: 100%;
      z-index: 1000;

      .header-menu-open & {
        opacity: 1;
      }
    }
  }
}

/* header ------------------------------------------------- */

#header {
  background-color: $white;
  height: 124px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  width: 980px;
  z-index: 1001;

  @media only screen and (max-width: $breakpoint-s) {
    border-bottom: 1px solid #e3e3e3;
    height: 143px;
    padding: 0;
    width: auto;

    body.webview & {
      display: none;
    }
  }

  &.no-menu {
    height: 70px;

    @media only screen and (max-width: $breakpoint-s) {
      height: 54px;
    }
  }

  &.hide-navigation {
    @media only screen and (max-width: $breakpoint-s) {
      height: 102px;
    }
  }

  i-header {
    display: block;
    padding: 28px 0 0;

    @media only screen and (max-width: $breakpoint-s) {
      padding: 15px 12px;
    }

    h1 {
      font-size: 12px;
    }

    img {
      @media only screen and (max-width: $breakpoint-s) {
        height: auto;
        width: 58px;
      }
    }
  }
}
