/*
https://jp.vuejs.org/v2/guide/transitions.html
*/

/*
```
<transition name="fade">
    <p v-if="show">hello</p>
</transition>
```

のように使える
*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.overlay.fade-enter-active {
  transition:
    background-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out,
    visibility 0.1s ease-in-out;
}

.overlay.fade-leave-active {
  transition-duration: 0s;
}

.overlay.fade-enter,
.overlay.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}
