@import '@/scss/base/variables';
@import '../../base';
@import '../../lib/blocks/alert-box';
@import './header';
@import './footer';

$red: brand-color('beige');
$img-path: '@/images/';

// common ------------------------------------------- */

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

body {
  background: #fff;
  color: $gray900;
  font-family: $font-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-wrap: anywhere;
  text-size-adjust: none;
}

a {
  color: #a06c4b;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    &::before,
    &::after {
      text-decoration: none;
    }
  }
}

.name {
  a {
    color: $gray900;
    line-height: 1;
  }
}

/* common elements/classes------------------------------------------- */

div {
  text-align: left;
}

.hidden {
  display: none;
}

.center {
  text-align: center;
}

._new {
  color: $red;
  font-size: 10px;
  font-weight: normal;
  margin: 0 4px;
}

._small {
  font-size: 10px;
  font-weight: normal;
}

.clearfix {
  min-height: 1%;

  &::after {
    clear: both;
    content: '.';
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
    width: 0;
  }
}

/* text-overflow */

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#app-download-banner {
  display: none;

  @media only screen and (max-width: $breakpoint-s) {
    display: block;
    height: 76px;
  }
}

/* user interface ---------------------------------------- */

.action-button {
  %action-button-base {
    border: 1px solid brand-color('beige');
    border-radius: 0;
    cursor: pointer;
    display: block;
    overflow: hidden;
    padding: 1em;
    text-align: center;
    text-decoration: none;
  }

  @at-root {
    &__primary {
      @extend %action-button-base;

      background-color: brand-color('beige');
      color: $white !important;
      font-weight: 700;
    }

    &__secondary {
      @extend %action-button-base;

      background: $white;
      color: $black;
    }
  }
}

.ui-button {
  background: brand-color('beige');
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 3px 20px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background: brand-color('beige');
    text-decoration: none;
  }
}

.button-submit,
.button-cancel {
  cursor: pointer;
  font-size: 12px;
  padding: 4px 16px;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button-submit {
  background: brand-color('beige');
  border: brand-color('beige') 1px solid;
  color: #fff;
}

.button-cancel {
  background: #fff;
  border: 1px solid $border-color;
  color: $gray900;
  margin-right: 20px;

  &:hover {
    background: #fff;
  }
}

.button-more {
  position: relative;

  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    padding-left: 17px;

    &::before {
      content: $icon-arrow-right;
      font-family: $font-icon;
      font-size: 18px;
      left: 0;
      line-height: 1;
      position: absolute;
    }
  }

  @media only screen and (max-width: $breakpoint-s) {
    border: 1px solid $border-color;
    color: $gray900;
    display: block;
    font-size: 14px;
    padding: 7px 0;
    text-align: center;

    &:hover {
      text-decoration: none;
    }

    &::after {
      color: #8e8e8e;
      content: $icon-arrow-right;
      font-family: $font-icon;
      font-size: 28px;
      line-height: 1;
      position: absolute;
      right: 5px;
      top: 3px;
    }
  }
}

.ui-overlay {
  background-color: rgba(93, 93, 93, 80%);
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9998;
}

#ui-overlay {
  background-color: #5d5d5d;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9998;
}

.dialog {
  display: none;
}

.ui-dialog {
  border: 1px solid #e3e3e3;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 10000;

  &.fixed {
    position: fixed;
  }
}

.ui-dialog-content {
  background-color: #f7f7f7;
  border: #fff 8px solid;
  padding: 14px 36px 14px 20px;

  .ui-dialog-message {
    line-height: 24px;
  }

  &.without-close-button {
    padding: 14px 20px;
  }
}

.ui-dialog-button-close {
  background: transparent url('#{$img-path}ui/button-close.png');
  cursor: pointer;
  height: 21px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 21px;
}

.ui-dialog-buttons {
  margin: 20px 0 8px;
  text-align: center;
}

.ui-dialog-button {
  appearance: none;
  background-color: #fff;
  border: 1px solid $border-color;
  color: $gray900;
  display: inline-block;
  height: 34px;
  line-height: 32px;
  margin-top: 16px;
  padding: 0 8px;

  & + .ui-dialog-button {
    margin-left: 14px;
  }
}

.ui-message {
  background: #fff;
  border: #cfccc7 1px solid;
  left: 0;
  min-width: 320px;
  padding: 20px;
  position: absolute;
  top: 0;
  z-index: 1002;
}

.ui-message-text {
  color: #9e1912;
  line-height: 150%;
  margin-bottom: 20px;
}

.ui-message-buttons {
  text-align: center;
}

/* message type */
.ui-loading {
  line-height: 24px;
  min-height: 24px;

  img {
    margin-right: 12px;
    vertical-align: middle;
  }
}

/* container --------------------------------------------------- */

#container {
  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    min-width: 1020px;
  }
}

#content {
  margin: 0 auto;
  padding: 16px 20px 40px;
  position: relative;
  width: 980px;

  @media only screen and (max-width: $breakpoint-s) {
    padding: 12px 10px;
    width: auto;
  }

  #main {
    grid-area: main;
  }

  #sub {
    grid-area: sub;
  }

  h1 {
    font-size: 18px;

    @media only screen and (max-width: $breakpoint-s) {
      font-size: 16px;
    }
  }
}

#area {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  padding: 20px 20px 20px 10px;
  position: relative;
}

/* form ----------------------------------------- */

.form-intro {
  line-height: 150%;
  margin: 10px 0 20px;

  &.-flex {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    @media only screen and (max-width: $breakpoint-s) {
      flex-direction: column;
    }

    .action-button {
      @media only screen and (max-width: $breakpoint-s) {
        text-align: right;

        .action-button__primary {
          display: inline-block;
        }
      }
    }
  }
}

.form-footnote {
  color: #666;
  font-size: 11px;
  margin: 12px 0 20px 140px;
}

.form-error {
  color: #9e1912;
  font-weight: bold;
  margin: 10px 4px;
}

form.default {
  margin: 0 0 24px;

  h3 {
    border-bottom: 1px dashed #edeae3;
    font-size: 14px;
    font-weight: bold;
    margin: 24px 0;
    padding-bottom: 4px;

    &.first,
    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0;
  }

  p {
    margin: 6px 0;
  }

  th,
  td {
    padding: 6px;
  }

  th {
    color: #333;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    vertical-align: top;
    width: 140px;
  }

  td {
    font-size: 12px;
    padding-bottom: 18px;
  }

  table.form:not(.layout-fix) {
    @media only screen and (max-width: $breakpoint-s) {
      display: block;

      > tbody {
        display: block;

        > tr {
          display: block;

          > th,
          > td {
            display: block;
          }

          > th {
            width: auto;
          }

          > td {
            padding-bottom: 6px;
          }
        }
      }
    }
  }

  .required,
  .not-required {
    display: block;
    position: relative;

    &::after {
      background-color: #ad0008;
      border-radius: 2px;
      bottom: 0;
      color: #fff;
      content: '必須';
      font-size: 10px;
      font-weight: bold;
      height: 12px;
      line-height: 10px;
      margin-bottom: auto;
      margin-top: auto;
      padding: 1px 5px;
      position: absolute;
      right: 0;
      top: 0;

      @media only screen and (max-width: $breakpoint-s) {
        display: inline-block;
        margin-left: 10px;
        position: static;
      }
    }
  }

  .not-required::after {
    background-color: #bdbdbd;
    content: '任意';
  }

  input {
    &[type='text'],
    &[type='password'],
    &[type='email'],
    &[type='number'],
    &[type='tel'],
    &[type='url'] {
      background: #fff;
      height: 14px;
      line-height: 14px;
      width: 220px;

      @media only screen and (max-width: $breakpoint-s) {
        box-sizing: border-box;
        height: 36px;
        width: 100%;
      }
    }
  }

  textarea {
    background: #fff;
    width: 320px;

    @media only screen and (max-width: $breakpoint-s) {
      box-sizing: border-box;
      width: 100%;
    }
  }

  .note {
    color: #a69b8e;
    font-size: 11px;
    margin: 3px 0 0;

    a {
      color: #a69b8e;
      text-decoration: underline;
    }
  }
}

.form-guide {
  visibility: hidden;
  width: 170px;
}

label {
  &.error {
    background-color: #f9eeea;
    border: #9e1912 1px solid;
    color: #9e1912;
    display: block;
    font-size: 11px;
    line-height: 12px;
    padding: 4px;
  }

  &.checked {
    background: url('#{$img-path}icons/checked.png') no-repeat;
    display: block;
    height: 13px;
    width: 14px;
  }
}

.check-list {
  float: left;
  padding-right: 20px;
  text-align: left;

  label {
    height: 24px;
    line-height: 24px;
    padding-left: 4px;
  }

  input {
    vertical-align: middle;
  }
}

form.default td.shop-url {
  font-size: 14px;
  padding-bottom: 25px;
  vertical-align: top;
}

form.default td.shop-links {
  padding-bottom: 8px;

  label {
    display: block;
    margin-bottom: 3px;
  }

  input {
    max-width: 100%;
    width: 334px;
  }
}

.carrier-mail-alert {
  background-color: #ad0008;
  border-radius: 3px;
  color: #fff;
  display: none;
  font-size: 11px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 8px;
  position: relative;

  &::after {
    border-color: transparent transparent #ad0008;
    border-style: solid;
    border-width: 0 10px 8px;
    content: ' ';
    height: 0;
    left: 90px;
    position: absolute;
    top: -8px;
    width: 0;
  }

  span {
    font-weight: bold;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

/* item list ---------------------------------------- */

.item-list {
  .item {
    float: left;
    margin-bottom: 20px;
    margin-left: 20px;
    position: relative;
    width: 156px;

    &.none {
      margin-left: 0;
    }

    .photo {
      margin-bottom: 5px;

      a {
        display: block;
        height: 127px;
        overflow: hidden;
      }
    }

    .photo img {
      border: none;
      padding: 0;
    }

    .info {
      height: 39px;
      margin-top: 3px;
      padding-bottom: 15px;
      position: relative;

      .pref {
        color: #666;
      }
    }

    .name {
      width: 160px;
    }

    .like-button {
      bottom: 12px;
      position: absolute;
      right: 3px;
      top: auto;
    }
  }

  .price {
    bottom: 18px;
    left: 0;
    position: absolute;

    span {
      font-weight: normal;
    }

    .currency-code {
      font-size: 10px;
    }
  }

  .maker {
    bottom: 18px;
    left: 0;
    position: absolute;
    width: 100px;

    a {
      color: $link-text-color;
    }
  }
}

.price {
  color: $gray900;
}

#bread {
  color: $link-text-color;
  font-size: 11px;
  margin: 0 auto;
  padding: 0 20px;
  width: 980px;

  @media only screen and (max-width: $breakpoint-s) {
    overflow-x: auto;
    padding: 10px 0;
    white-space: nowrap;
    width: 100%;

    body.webview & {
      padding-bottom: 20px;
    }
  }

  li {
    display: inline;

    @media only screen and (max-width: $breakpoint-s) {
      overflow-x: auto;
    }

    &:first-child {
      @media only screen and (max-width: $breakpoint-s) {
        padding-left: 10px;
      }
    }

    &:last-child {
      @media only screen and (max-width: $breakpoint-s) {
        padding-right: 10px;
      }

      a {
        background: none;

        @media only screen and (max-width: $breakpoint-s) {
          text-decoration: underline;
        }
      }
    }
  }

  a {
    color: $link-text-color;
    display: inline;
    margin-right: -13px;
    padding-right: 28px;
    position: relative;
    text-decoration: none;

    &.material {
      background: none;
      margin-right: 0;
      padding-right: 0;
    }

    &:hover span {
      text-decoration: underline;
    }

    &::after {
      color: $link-text-color;
      content: $icon-arrow-right;
      font-family: $font-icon;
      font-size: 14px;
      position: absolute;
      right: 11px;
      top: -1px;
    }
  }
}

/* LIKE, FOLLOW, MESSAGE ----------------------------------------------- */

.listing-item-sold {
  display: block;
  position: relative;

  &::after {
    background-color: #bdbdbd;
    color: #fff;
    content: 'SOLD';
    font-size: 10px;
    font-weight: bold;
    left: 5px;
    padding: 0 3px;
    position: absolute;
    top: 5px;
  }
}

.listing-item-new,
.listing-item-relisted {
  .name {
    &::before {
      background-color: $white;
      display: inline-block;
      font-size: 10px;
      line-height: 14px;
      margin-right: 3px;
      padding: 1px 3px 0;
      vertical-align: 1px;
    }
  }
}

.listing-item-new {
  .name {
    &::before {
      border: 1px solid $dark-red;
      color: $dark-red;
      content: 'NEW';
    }
  }
}

.listing-item-relisted {
  .name {
    &::before {
      border: 1px solid $gray900;
      color: $gray900;
      content: '再出品';
    }
  }
}

/* ファイルコピー/編集リンク ------------------------------------------------ */

.file-edit,
.file-copy,
.file-delete {
  background: transparent url('#{$img-path}icons/file.png') no-repeat left top;
  color: #333;
  cursor: pointer;
  display: block;
  line-height: 16px;
  padding-left: 20px;
}

.file-edit {
  background-position: 0 0;
}

.file-copy {
  background-position: 0 -20px;
}

.file-delete {
  background-position: 0 -40px;
}

/* h2,h3 右のもっと見る ------------------------------------------------ */

.heading {
  h2,
  h3 {
    float: left;
  }
}

.heading-read-more {
  color: $link-text-color;
  display: block;
  float: left;
  font-size: 11px;
  margin-left: 18px;
  margin-top: 1px;
  padding-left: 12px;
  position: relative;

  &:hover {
    text-decoration: underline;
  }

  &::before {
    content: $icon-arrow-right;
    font-family: $font-icon;
    font-size: 16px;
    left: -5px;
    line-height: 16px;
    position: absolute;
    top: 0;
  }
}

.pager {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
  padding-top: 0;
  text-align: center;

  a,
  .current {
    border-radius: 2px;
    font-size: 14px;
    line-height: 1em;
    padding: 7px 9px;
    vertical-align: bottom;
  }

  a {
    background-color: $white;
    border: 1px solid $gray180;
    color: brand-color('beige');
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .current {
    background-color: #dcdcdc;
    border: 1px solid #dcdcdc;
    color: #fff;
  }

  .page {
    color: brand-color('beige');
    display: block;
    font-size: 10px;
    margin-top: 5px;
    padding-top: 4px;
  }

  a[rel='prev'],
  a[rel='next'] {
    background-color: brand-color('beige');
    border: 1px solid brand-color('beige');
    color: #fff;
    overflow: hidden;
    padding: 0 12px;

    &:hover {
      opacity: 0.8;
    }

    &::after {
      color: #fff;
      content: $icon-arrow-right;
      display: block;
      font-family: $font-icon;
      font-size: 2.5em;
      line-height: 1;
      margin: -4px 0 -3px;
    }
  }

  a[rel='prev'] {
    &::after {
      content: $icon-arrow-left;
    }
  }

  .pager-dot {
    color: brand-color('beige');
    margin-left: 6px;
  }
}

.tooltip {
  font-size: 11px;
  font-weight: normal;
  position: relative;

  dt {
    color: $dark-red;
    cursor: pointer;
    display: inline;
    text-decoration: underline;
  }

  dd {
    background: #fff;
    border: #e7e6dd 1px solid;
    display: none;
    left: -21px;
    padding: 5px;
    position: absolute;
    top: 25px;
    width: 300px;
    z-index: 100;

    > div {
      background: #f7f7f7;
      padding: 10px;
    }
  }

  .arrow {
    background: url('@/images/ui/tooltip-arrow.gif') no-repeat left top;
    height: 7px;
    left: 20px;
    position: absolute;
    top: -7px;
    width: 13px;
  }

  .button-close {
    background: url('@/images/ui/button-close.png') no-repeat 0 0;
    cursor: pointer;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 21px;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 21px;
  }
}

#area-form {
  background-color: $white;
  border: 1px solid #e5ddc7;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 20px;
  padding: 20px 30px;

  @media only screen and (max-width: $breakpoint-s) {
    margin-top: 15px;
    padding: 20px;
  }

  th,
  td {
    font-size: 14px;

    @media only screen and (max-width: $breakpoint-s) {
      display: block;
    }
  }

  @media only screen and (max-width: $breakpoint-s) {
    th {
      padding: 6px 0;
    }

    td {
      padding: 6px 0 0;
    }
  }

  .button-submit {
    font-size: 14px;
  }
}

.result-zero {
  font-size: 14px;
  line-height: 1.8;
  padding: 60px 0;
  text-align: center;
}
