@use 'sass:color';
@import './variables';

/* stylelint-disable scss/dollar-variable-pattern */
$--font-path: '@/fonts';
$--color-primary: brand-color('beige');
$--color-text-regular: $gray900;
$--popover-font-size: 12px;
$--popover-title-font-size: 12px;
$--popover-title-font-color: $gray900;
$--dialog-padding-primary: 10px;
/* stylelint-enable scss/dollar-variable-pattern */

@import 'element-ui/packages/theme-chalk/src/index';

// iOSで2回タップしないとSelectが選択できない問題を解決する
// https://stackoverflow.com/questions/61669363/element-ui-vuejs-select-input-double-tap-bug-on-ios
.el-scrollbar__bar {
  opacity: 1 !important;
}

.el-autocomplete {
  width: 100%;
}

.el-input__inner,
.el-textarea__inner {
  border-radius: 0;
  color: inherit;
  font-size: 16px;
  padding: 16px !important;

  &:focus {
    border-color: #c0c4cc;
  }
}

.el-input.error .el-input__inner,
.el-autocomplete.error .el-input__inner,
.el-textarea.error .el-textarea__inner,
.el-select.error .el-input__inner {
  border: 1px solid $dark-red;

  &:focus {
    outline: 1px solid $dark-red;
  }
}

.el-input__inner {
  height: 56px;
}

.el-select .el-input__inner:focus,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #c0c4cc;
}

.el-input--medium .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
  padding: 4px 16px !important;
}

.el-input--mini {
  .el-input__inner {
    height: 40px !important;
    line-height: 40px !important;
    padding: 10px !important;
  }

  .el-input__suffix {
    right: 5px;
  }
}

.el-input__suffix {
  right: 16px;
}

.el-input__icon {
  line-height: 56px;
}

.el-input .el-input__clear {
  font-size: 22px;
}

.el-select {
  width: 100%;
}

.el-select-dropdown {
  max-width: calc(100% - 30px);
}

.el-select-dropdown__item {
  height: auto;
  line-height: 1.8;
  overflow: initial;
  padding-bottom: 5px;
  padding-top: 5px;
  text-overflow: initial;
  white-space: initial;

  &.selected {
    color: inherit;
  }
}

.el-button {
  font-size: 16px;
  height: 52px;
  width: 100%;

  &.is-disabled,
  &.is-disabled:focus,
  &.is-disabled:hover {
    background-color: $gray300;
    border-color: $gray300;
    color: $white;
    cursor: inherit;
    font-weight: bold;
  }
}

.el-button--small {
  font-size: 14px;
  height: 36px;
  padding-bottom: 4px;
  padding-top: 4px;
}

.el-button--secondary {
  &:hover,
  &:active,
  &:focus {
    background: none;
    border-color: #bf9478;
    color: inherit;
  }
}

.el-button--primary {
  font-weight: bold;

  &:focus {
    background-color: $--color-primary;
    border-color: $--color-primary;
  }

  &:active,
  &:focus-visible {
    background-color: color.mix($--color-white, $--color-primary, $--button-hover-tint-percent);
    border-color: color.mix($--color-white, $--color-primary, $--button-hover-tint-percent);
  }
}

.el-switch {
  height: 32px;
}

.el-popover {
  font-size: 14px;
  line-height: 1.5;
  min-width: 140px;
}

:not(.v-modal) + .el-popover {
  z-index: inherit !important;
}

.el-popover__title {
  font-size: 14px;
  font-weight: bold;
}

.el-popover__reference:focus:not(.focusing) {
  outline-width: initial;
}

.el-popper[x-placement^='bottom'] {
  margin-top: 8px;
}

.el-drawer {
  height: auto !important;
}

.el-dialog__wrapper {
  display: flex;
  justify-content: center;

  &.dialog-fade-enter-active,
  &.dialog-fade-leave-active {
    animation: none;
  }
}

.el-dialog {
  margin: auto !important;
  max-width: calc(100% - 40px);
}

.el-dialog__body {
  padding: 12px 32px 32px;

  > p:last-child {
    margin-bottom: 0;
  }
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 24px;
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: $--color-info;
  opacity: 0.8;
}

.el-dialog.is-fullscreen {
  border-radius: 0;
  display: flex;
  max-width: inherit;

  img {
    vertical-align: bottom;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__headerbtn {
    position: fixed;
    z-index: 10;
  }

  .el-dialog__close {
    font-size: 40px;
    font-weight: bold;
  }

  .el-dialog__body {
    margin: auto;
    padding: 0;
  }
}

.el-notification {
  max-width: calc(100% - 32px);
}

.el-notification__icon {
  display: none;
}

.el-notification__title {
  font-size: 14px;
}
